import React, { useState } from "react";
import ProductCard from "../productCard/productCard";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

//CSS
import * as StyledComponents from "./serachByWheel.module.scss";

//Data
import DiamData from "../../data/export-diameter_wheels.json";
import PcdData from "../../data/export-pcd_wheels.json";

// Markup
export const SearchByWheel = () => {
  const [Diameter, setDiameter] = useState();
  const [PCD, setPCD] = useState();
  const [AvalItems, setAvalItems] = useState([{}]);
  const [DisplayCards, setDisplayCards] = useState({ display: "none" });

  //-----------FILTERS--------------------
  const [DispFilterHeader, setDispFilterHeader] = useState({
    display: "inline",
  });
  const [DispFilterBox, setDispFilterBox] = useState({ display: "none" });
  const [ItemsNoFilter, setItemsNoFilter] = useState([]);
  const [WidthsSelection, setWidthsSelection] = useState([]);
  const [OffsetsSelection, setOffsetsSelection] = useState([]);
  const [BrandsSelection, setBrandsSelection] = useState([]);
  const [PriceSelection, setPriceSelection] = useState([]);
  const [FWIDTHS, setFWIDTHS] = useState([]);
  const [FOFFSETS, setFOFFSETS] = useState([]);
  const [FBRANDS, setFBRANDS] = useState([]);
  const [FMINPRICE, setFMINPRICE] = useState(0);
  const [FMAXPRICE, setFMAXPRICE] = useState(100);
  const [PriceRangeValue, setPriceRangeValue] = useState([
    FMINPRICE,
    FMAXPRICE,
  ]);

  function openFilter() {
    setDispFilterBox({ display: "inline" });
    setDispFilterHeader({ display: "none" });
  }

  function widthsValue(e) {
    let v = parseFloat(e);
    let arr = WidthsSelection;
    if (arr.indexOf(v) === -1) {
      //add to selection
      arr.push(v);
    } else {
      //remove from selection
      let index = arr.indexOf(v);
      arr.splice(index, 1);
    }
    setWidthsSelection(arr);
    //console.log(arr);
  }

  function offsetsValue(e) {
    let v = parseFloat(e);
    let arr = OffsetsSelection;
    if (arr.indexOf(v) === -1) {
      //add to selection
      arr.push(v);
    } else {
      //remove from selection
      let index = arr.indexOf(v);
      arr.splice(index, 1);
    }
    setOffsetsSelection(arr);
    //console.log(arr);
  }

  function brandsValue(e) {
    let arr = BrandsSelection;
    if (arr.indexOf(e) === -1) {
      //add to selection
      arr.push(e);
    } else {
      //remove from selection
      let index = arr.indexOf(e);
      arr.splice(index, 1);
    }
    setBrandsSelection(arr);
    //console.log(arr);
  }

  const handlePriceChange = (event, newValue) => {
    setPriceRangeValue(newValue);
    setPriceSelection(newValue);
  };

  const handleSubmitFilter = () => {
    //console.log("filter");
    //console.log(WidthsSelection);
    //console.log(OffsetsSelection);
    //console.log(BrandsSelection);
    //console.log(PriceSelection);
    //console.log(ItemsNoFilter);
    //console.log(AvalItems);

    setDispFilterBox({ display: "none" });

    let newDisplay = [];
    ItemsNoFilter.map((item) => {
      if (
        WidthsSelection.indexOf(item.width) !== -1 ||
        WidthsSelection.length === 0
      ) {
        if (
          OffsetsSelection.indexOf(item.offset) !== -1 ||
          OffsetsSelection.length === 0
        ) {
          if (
            BrandsSelection.indexOf(item.brand) !== -1 ||
            BrandsSelection.length === 0
          ) {
            if (
              (item.price >= PriceSelection[0] &&
                item.price <= PriceSelection[1]) ||
              PriceSelection.length === 0
            ) {
              newDisplay.push(item);
            }
          }
        }
      }

      return <></>;
    });
    setAvalItems(newDisplay);

    setWidthsSelection([]);
    setOffsetsSelection([]);
    setBrandsSelection([]);
    setDispFilterHeader({ display: "inline" });
  };

  //return filters
  function returnFilters() {
    return (
      <>
        <button
          className={StyledComponents.filterHeader}
          style={DispFilterHeader}
          onClick={() => openFilter()}
        >
          + AFFICHER LES FILTRES
        </button>
        <div className={StyledComponents.filters} style={DispFilterBox}>
          {/*<p>Produits affiches: {totalwheels}</p>*/}
          <h4>Largeurs : </h4>
          <FormGroup row>
            {FWIDTHS.map((item) => {
              return (
                <FormControlLabel
                  key={Math.random()}
                  control={<Checkbox />}
                  label={item}
                  value={item}
                  onChange={(e) => widthsValue(e.target.value)}
                />
              );
            })}
          </FormGroup>
          <h4>Offsets : </h4>
          <FormGroup row>
            {FOFFSETS.map((item) => {
              return (
                <FormControlLabel
                  key={Math.random()}
                  control={<Checkbox />}
                  label={item}
                  value={item}
                  onChange={(e) => offsetsValue(e.target.value)}
                />
              );
            })}
          </FormGroup>
          <h4>Marques : </h4>
          <FormGroup row>
            {FBRANDS.map((item) => {
              return (
                <FormControlLabel
                  key={Math.random()}
                  control={<Checkbox />}
                  label={item}
                  value={item}
                  onChange={(e) => brandsValue(e.target.value)}
                />
              );
            })}
          </FormGroup>
          <h4>Prix : </h4>
          <Box sx={{ margin: "3em 2em 0 2em" }}>
            <Slider
              getAriaLabel={() => "Price range"}
              value={PriceRangeValue}
              onChange={handlePriceChange}
              min={FMINPRICE}
              max={FMAXPRICE}
              valueLabelDisplay="on"
              sx={{
                color: "var(--darkgreen)",
              }}
            />
          </Box>
          <br />
          <Button
            variant="contained"
            fullWidth={true}
            sx={{
              backgroundColor: "var(--darkgreen)",
              borderRadius: "0",
              [`&:hover, &.Mui-focusVisible`]: {
                backgroundColor: "var(--darkgreen)",
              },
            }}
            onClick={() => handleSubmitFilter()}
          >
            Appliquer
          </Button>
        </div>
      </>
    );
  }

  //-----------FORM SUBMIT--------------------

  //handle the form sublit
  const handleSubmit = (e) => {
    e.preventDefault();
    getWheels();
    setDisplayCards({ display: "flex" });
  };

  //get filters and wheels
  async function getWheels() {
    await fetch(`https://www.wheels-api.com/searchWheels/${Diameter}/${PCD}`)
      .then((res) => res.json())
      .then(
        (result) => {
          setAvalItems(result[0].wheels);
          setItemsNoFilter(result[0].wheels);
          //set filters
          setItemsNoFilter(result[0].wheels);
          setFWIDTHS(result[0].widths);
          setFOFFSETS(result[0].offsets);
          setFBRANDS(result[0].brands);
          setFMINPRICE(result[0].prices[0]);
          setFMAXPRICE(result[0].prices[1]);
          setPriceRangeValue([result[0].prices[0], result[0].prices[1]]);
          //remove all previus filters
          setWidthsSelection([]);
          setOffsetsSelection([]);
          setBrandsSelection([]);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  return (
    <>
      <form
        className={StyledComponents.form}
        onSubmit={(event) => {
          handleSubmit(event);
        }}
      >
        <h2>Sélectionnez votre taille de jante / mag</h2>
        <div className={StyledComponents.formControls}>
          <select value={PCD} onChange={(e) => setPCD(e.target.value)}>
            <option>Configuration des boulons</option>
            {PcdData.map((item) => {
              return (
                <option key={item} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
          <select
            value={Diameter}
            onChange={(e) => setDiameter(e.target.value)}
          >
            <option>Diamètre</option>
            {DiamData.map((item) => {
              return (
                <option key={item} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
          <button type="submit">Rechercher</button>
        </div>
      </form>

      <div className={StyledComponents.itemsDisplay} style={DisplayCards}>
        {/*FILTERS*/}
        {returnFilters()}

        {/*ITEMS CARDS*/}
        {AvalItems.map((item) => {
          return (
            <>
              <ProductCard
                image={item.photo}
                sku={item.sku}
                brand={item.brand}
                name={item.name}
                stylenum={item.stylenum}
                price={item.price}
                diam={item.diameter}
                width={item.width}
                season={item.season}
                color={item.color}
                finish={item.finish}
                offset={item.offset}
                pcd1={item.pcd1}
                pcd2={item.pcd2}
                supplier={item.supplier}
              />
            </>
          );
        })}
      </div>
    </>
  );
};
