// extracted by mini-css-extract-plugin
export var card = "productCard-module--card--7D-m9";
export var lifted = "productCard-module--lifted--6Gijb";
export var snowContainer = "productCard-module--snowContainer--Zy-D1";
export var snow = "productCard-module--snow--2ASbw";
export var transparent = "productCard-module--transparent--kvG5+";
export var magimage = "productCard-module--magimage--KqguF";
export var sku = "productCard-module--sku--7iAyQ";
export var colorFinish = "productCard-module--colorFinish--SCtlZ";
export var price = "productCard-module--price--ZaaAp";
export var star = "productCard-module--star--V9+Tf";
export var slowshow = "productCard-module--slowshow--50DeQ";